// websiteLogActions.js
import axios from 'axios';
import {
  FETCH_WEBSITE_LOG_REQUEST,
  FETCH_WEBSITE_LOG_SUCCESS,
  FETCH_WEBSITE_LOG_FAILURE,
} from '../constants'; // Import the action types from constants.js

import { API_URL, WESBITE_LOG_DATA, DOMAIN_URL } from '../../constants/constants';

export const fetchWebsiteLogRequest = () => ({
  type: FETCH_WEBSITE_LOG_REQUEST,
});

export const fetchWebsiteLogSuccess = (data) => ({
  type: FETCH_WEBSITE_LOG_SUCCESS,
  payload: data,
});

export const fetchWebsiteLogFailure = (error) => ({
  type: FETCH_WEBSITE_LOG_FAILURE,
  payload: error,
});

// Async action creator to fetch website log data
export const fetchData = () => {
  return async (dispatch) => {
    
    dispatch(fetchWebsiteLogRequest());
    try {

      const response = await axios.get(`${DOMAIN_URL}${API_URL}${WESBITE_LOG_DATA}`);
      dispatch(fetchWebsiteLogSuccess(response.data));
    } catch (error) {
      dispatch(fetchWebsiteLogFailure(error));
    }
  };
};
