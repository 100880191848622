import React from 'react'
import './index.css';
import RightSideTitle from '../RightSideTitle';
import ExternalLink from '../ExternalLink';


export default function index({ work_experience }) {

    return (
        <div className="about">
            <RightSideTitle title="work experience" />
            {work_experience.map((experience) => (
                <div className="box" key={experience.id}>
                    <div className="year_company">
                        <h5>{experience.period}</h5>
                        <h5>{experience.company_name}</h5>
                        <div className="company_icon">
                            <ExternalLink href={experience.company_url} >
                                <img lazyload="" alt={experience.company_image_alt} src={experience.company_icon} />
                            </ExternalLink>
                        </div>
                    </div>
                    <div className="text">
                        <h4>{experience.job_title}</h4>
                        <p dangerouslySetInnerHTML={{ __html: experience.job_detail }}></p>
                    </div>
                </div>
            ))}
        </div>
    )
}
