// websiteLogReducer.js

import {
    FETCH_PRODUCT_DATA_REQUEST,
    FETCH_PRODUCT_DATA_SUCCESS,
    FETCH_PRODUCT_DATA_FAILURE,
    UPDATE_SEARCH_RESULTS,
} from '../constants';

const initialState = {
    loading: false,
    error: null,
    lastUpdate: [],
    walmartjson: [],
    costcojson: [],
    tntjson: [],
    searchTerm: '',
    walmartSearchResults: [],
    costcoSearchResults: [],
    tntSearchResults: [],
};

const groceryFlyerSearchReducer = (state = initialState, action) => {

    switch (action.type) {
        case FETCH_PRODUCT_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_PRODUCT_DATA_SUCCESS:
            const { walmartjson, costcojson, tntjson } = distributeData(action.payload);
            return {
                ...state,
                loading: false,
                lastUpdate: action.payload[0] ? action.payload[0].date : [],
                walmartjson,
                costcojson,
                tntjson,
            };
        case FETCH_PRODUCT_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_SEARCH_RESULTS:
            const searchTerm = action.payload;

            const walmartSearchResults = state.walmartjson.filter((item) =>
                item.productName.toLowerCase().includes(searchTerm)
            );
            const costcoSearchResults = state.costcojson.filter((item) =>
                item.productName.toLowerCase().includes(searchTerm)
            );
            const tntSearchResults = state.tntjson.filter((item) =>
                item.productName.toLowerCase().includes(searchTerm)
            );
            return {
                ...state,
                searchTerm,
                walmartSearchResults,
                costcoSearchResults,
                tntSearchResults,
            };
        default:
            return state;
    }
};

const distributeData = (data) => {
    const walmartjson = [];
    const costcojson = [];
    const tntjson = [];

    data.forEach((item) => {
        if (item.groceryName === 'walmart') {
            walmartjson.push(item);
        } else if (item.groceryName === 'costco') {
            costcojson.push(item);
        } else if (item.groceryName === 'tnt') {
            tntjson.push(item);
        }
    });

    return { walmartjson, costcojson, tntjson };
};

export default groceryFlyerSearchReducer;
