import React, { useEffect }  from 'react'
import './index.css';

export default function ProductsHorizontalScroll({ children }) {
    useEffect(() => {
        const containers = document.getElementsByClassName('horizontal-scroll');

        const scrollspeed = 100

        for (let i = 0; i < containers.length; i++) {
            containers[i].addEventListener('wheel', function (e) {
                if (e.deltaY > 0) {
                    this.scrollLeft += scrollspeed;
                    e.preventDefault();
                } else {
                    this.scrollLeft -= scrollspeed;
                    e.preventDefault();
                }
            });
        }

        // Clean up event listeners on component unmount
        return () => {
            for (let i = 0; i < containers.length; i++) {
                containers[i].removeEventListener('wheel', function (e) {
                    if (e.deltaY > 0) {
                        this.scrollLeft += scrollspeed;
                        e.preventDefault();
                    } else {
                        this.scrollLeft -= scrollspeed;
                        e.preventDefault();
                    }
                });
            }
        };
    }, []);

    return (
        <div>
            <div className='horizontal-scroll'>
                <div className='horizontal-scroll-content'>{children}</div>

            </div>
            <div className='horizontal-scroll-border-bottom'></div>
        </div>
    );
}

