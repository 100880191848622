import { combineReducers } from "redux";

import websiteLogReducer from "./websiteLog";
import resumeReducer from "./resume";
import groceryFlyerSearchReducer from "./groceryFlyerSearch";

export default combineReducers({
    websiteLog: websiteLogReducer,
    resume: resumeReducer,
    products: groceryFlyerSearchReducer,
})