import React from 'react'
import './index.css';
import ExternalLink from '../ExternalLink';

export default function Contact({ contact }) {

  return (
    <div className='contactInfo'>
      <h5 className='title'>
        Contact Info
      </h5>
      <ul>
        {Object.entries(contact).map(([key, value]) => (
          <li key={key}>
            <span className="icon">
              <i className={value.icon} aria-hidden="true"></i>
            </span>
            {value.hyperlink ? (
              <span className="text">
                {value.hyperlink.includes('@') ? (
                  <ExternalLink href={`mailto:${value.hyperlink}`}>
                  {value.content}
                </ExternalLink>
                ) : (
                  <ExternalLink href={value.hyperlink.startsWith('www') ? `https://${value.hyperlink}` : value.hyperlink} >
                    {value.content}
                  </ExternalLink>
                )}
              </span>
            ) : (
              <span className="text">{value.content}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
