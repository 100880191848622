import React from 'react';
import './index.css';

const ExternalLink = (props) => {
    const { href, className, children, ...rest } = props;

    return (
        <a href={href} target="_blank" rel="noopener noreferrer" className={className} {...rest}>
            {children}
        </a>
    );
};

export default ExternalLink;
