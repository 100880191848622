import React from 'react';
import './index.css';
import WorkExperence from '../WorkExperence';
import Works from '../Works';
import Skills from '../Skills';

function RightComponent({ data }) {
    return (
        <div className="right-component" >
            <Works works={data.works} />
            <WorkExperence work_experience={data.work_experience} />
            <Skills skills={data.skills} />
        </div>
    );
}

export default RightComponent;