import React from 'react'
import './index.css';

export default function Language
  ({ language }) {
  return (
    <div className="contactInfo language">
      <h5 className="title">Language</h5>
      <ul>
        {language.map((lang, index) => {
          return (
            <li key={index}>
              <span className="text">{lang}</span>
            </li>
          );
        })}
      </ul>
    </div>
  )
}
