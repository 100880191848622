import React from 'react';
import ProductsHorizontalScroll from '../ProductsHorizontalScroll';
import './index.css';
import ProductsItemContainer from '../ProductsItemContainer';
import { v4 as uuidv4 } from 'uuid';

const ProductsItemList = ({ data }) => {

  return (
    <ProductsHorizontalScroll>
      {data ? (
        <div className='ProductItemListContainer'>
          {data.map((product) => (
            <div className='ProductItems' key={uuidv4()}>
              <ProductsItemContainer
                key={product.id}
                productLink={product.productUrl}
                imageSrc={product.imageUrl}
                productName={product.productName}
                prevPrice={product.previousPrice}
                currPrice={product.currentPrice}
                status={product.productStatus ? product.productStatus : 'VIEW ONLINE'}
              />

            </div>
          ))}
        </div>
      ) : (
        <div className='ProductItemListLoading'>
          <p>Loading...</p>
        </div>
      )}
    </ProductsHorizontalScroll>
  );
};

export default ProductsItemList;



