// constants.js
export const FETCH_WEBSITE_LOG_REQUEST = 'FETCH_WEBSITE_LOG_REQUEST';
export const FETCH_WEBSITE_LOG_SUCCESS = 'FETCH_WEBSITE_LOG_SUCCESS';
export const FETCH_WEBSITE_LOG_FAILURE = 'FETCH_WEBSITE_LOG_FAILURE';

// resumeTypes.js
export const FETCH_RESUME_REQUEST = 'FETCH_RESUME_REQUEST';
export const FETCH_RESUME_SUCCESS = 'FETCH_RESUME_SUCCESS';
export const FETCH_RESUME_FAILURE = 'FETCH_RESUME_FAILURE';

// constants.js

export const FETCH_PRODUCT_DATA_REQUEST = 'FETCH_PRODUCT_DATA_REQUEST';
export const FETCH_PRODUCT_DATA_SUCCESS = 'FETCH_PRODUCT_DATA_SUCCESS';
export const FETCH_PRODUCT_DATA_FAILURE = 'FETCH_PRODUCT_DATA_FAILURE';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
