import React from 'react'
import './index.css';
import RightSideTitle from '../RightSideTitle';
import { RayH4, RayH5 } from '../MyHtmlElements';

export default function Skills({ skills }) {
    return (
        <div className="about">
            <div className="skills">
                <RightSideTitle title="Professional Skills" />
                {skills.map((skill) => (
                    <div className="box skill_box" key={skill.skill_type}>
                        <div className="skill_type">
                            <RayH4>{skill.skill_type}</RayH4>
                        </div>
                        <div className="text">
                            <RayH5>{skill.skill_detail}</RayH5>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
