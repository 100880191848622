// resumeActions.js
import axios from 'axios';
import {
  FETCH_RESUME_REQUEST,
  FETCH_RESUME_SUCCESS,
  FETCH_RESUME_FAILURE,
} from '../constants';

import { API_URL, RESUME_DATA, DOMAIN_URL } from '../../constants/constants';

export const fetchResumeRequest = () => ({
  type: FETCH_RESUME_REQUEST,
});

export const fetchResumeSuccess = (data) => ({
  type: FETCH_RESUME_SUCCESS,
  payload: data,
});

export const fetchResumeFailure = (error) => ({
  type: FETCH_RESUME_FAILURE,
  payload: error,
});

// Async action creator to fetch resume data
export const fetchResumeData = () => {
  return async (dispatch) => {
    dispatch(fetchResumeRequest());
    try {
      const response = await axios.get(`${DOMAIN_URL}${API_URL}${RESUME_DATA}`);
      dispatch(fetchResumeSuccess(response.data));
    } catch (error) {
      dispatch(fetchResumeFailure(error));
    }
  };
};
