// resumeReducer.js
import {
    FETCH_RESUME_REQUEST,
    FETCH_RESUME_SUCCESS,
    FETCH_RESUME_FAILURE,
  } from '../constants';
  
  const initialState = {
    jsonData: null,
    error: null,
  };
  
  const resumeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_RESUME_REQUEST:
        return {
          ...state,
          error: null,
        };
      case FETCH_RESUME_SUCCESS:
        return {
          ...state,
          jsonData: action.payload,
          error: null,
        };
      case FETCH_RESUME_FAILURE:
        return {
          ...state,
          jsonData: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default resumeReducer;
  