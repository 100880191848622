import React from 'react'
import './index.css';
import RightSideTitle from '../RightSideTitle';
import ExternalLink from '../ExternalLink';

export default function Works({ works }) {
    return (
        <div className="about">
            <RightSideTitle title="Works" />
            {works.map((work) => (
                <p key={work.id} style={{ fontWeight: '450' }}>
                    {work.name}
                    <br />
                    <ExternalLink href={work.url}>
                        {work.url_content}
                    </ExternalLink>
                    {work.video_url && (
                        <React.Fragment>
                            <br />
                            <iframe width="320" height="190" src={work.video_url} title="Video"></iframe>
                        </React.Fragment>
                    )}
                </p>
            ))}
        </div>
    )
}
