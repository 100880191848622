import React from 'react';
import './index.css';
import Title from '../Title';
import Contact from '../Contact';
import Education from '../Education';
import Language from '../Language/';

function LeftComponent({ data }) {

    return (
        <div className="left-component" >
            <Title name={data.name} title={data.title} />
            <Contact contact={data.contact_info} />
            <Education education={data.education} />
            <Language language={data.language}/>
        </div>
    );
}

export default LeftComponent;