import React from 'react'
import './index.css';

export default function Title({ name, title }) {

    return (
        <div className='profileText'>
            <h2>
                {name}
                <br />
                <span>{title}</span>
            </h2>
        </div>
    )
}
