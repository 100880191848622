import React, { useEffect } from 'react'
import './index.css';

import { Banner } from '../../components/Banner'

import { connect } from 'react-redux';
import { fetchData } from '../../redux/actions/websiteLog';

function HomePage({ websiteLog, loading, error, fetchData }) {
    useEffect(() => {

      fetchData(); // Use the fetchData action creator
    }, []);
  
    return (
      <div>
        <Banner topText="Hello, Welcome to my website" bottomText="Website Notification:" />
        {loading ? (
          <div className="website-log">Loading website log...</div>
        ) : error ? (
          <div className="website-log">Error fetching website log data</div>
      ) : (
          websiteLog.map((jsonData, index) => (
            <div key={index} className="website-log">
              <div className="website-log-date">{jsonData.date}</div>
              <div className="website-log-content">{jsonData.log}</div>
            </div>
          ))
        )}
      </div>
    );
  }

const mapStateToProps = (state) => ({
    websiteLog: state.websiteLog.websiteLog,
    loading: state.websiteLog.loading,
    error: state.websiteLog.error,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: () => dispatch(fetchData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);