import React from 'react';
import './App.css';
import Resume from './components/Resume';
import GroceryFlyerSearch from './pages/GroceryFlyerSearch';
import ResponsiveNavbar from './pages/ResponsiveNavbar';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import SystemDesignDiagram from './pages/SystemDesignDiagram';

function App() {
  return (
    <div className="App">
      <ResponsiveNavbar />
      <Routes>
        <Route path='' element={<HomePage />} />
        <Route path='/groceryFlyerSearch' element={<GroceryFlyerSearch />} />
        <Route path='/systemDesignDiagram' element={<SystemDesignDiagram />} />
        <Route path='/resume' element={<Resume />} />
      </Routes>

    </div>
  );
}

export default App;
