import React, { useEffect, useState } from 'react'
import "./index.css";

function SystemDesignDiagram() {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      console.error('Error:', error);
      console.error('Error Info:', errorInfo);
      setHasError(true);
    };

    // ComponentDidCatch equivalent for function components
    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return <div>Something went wrong while loading the system design diagram.</div>;
  }

  return (
    <div className="centered-image-container">
      
      <img src="/images/System_Design_Diagram.png" alt="System Design Diagram" className="centered-image" />
    </div>
  );
}

export default SystemDesignDiagram;