import React, { useState } from 'react'
import './index.css';
import { PRODUCT_DEFAULT_IMAGE } from '../../constants/constants';
import ExternalLink from '../ExternalLink';


const ProductsItemContainer = ({ productLink, imageSrc, productName, prevPrice, currPrice, status }) => {

  const [isImageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const imageContent = (
    <div className="image-container">
      {!isImageError ? (
        <img loading="lazy" src={imageSrc} alt={productName} onError={handleImageError} />
      ) : (
        <img loading="lazy" src={PRODUCT_DEFAULT_IMAGE} alt="Default" />
      )}
    </div>
  );

  const productInfo = (
    <div className="product-info">
      <div className="product-name">{productName}</div>
      <div className="price-container">
        <div className="curr-price">{currPrice}</div>
        <div className="prev-price">{prevPrice}</div>
      </div>
      <div className={`status ${productLink.length === 0 ? 'grey-status' : ''}`}>{status}</div>
    </div>
  );

  return (
    <div className="product-item">
      {productLink.length === 0 ? (
        <div>
          {imageContent}
          {productInfo}
        </div>
      ) : (
        <ExternalLink href={productLink} className="product-link">
          {imageContent}
          {productInfo}
        </ExternalLink>
      )}
    </div>
  );
};

export default ProductsItemContainer;
