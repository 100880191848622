export const API_DEV_BASE_URL = 'https://localhost';
export const API_PROD_BASE_URL = 'https://www.rayyulxlxx.com';

export const API_URL = '/api';
export const RESUME_DATA = '/resume_data';
export const WESBITE_LOG_DATA = '/website_log';

export const PRODUCTS_API = '/products'
export const ALL_PRODUCTS = '/all';

export const TEST_PRODUCTS_DATA = '/test_data.json';

export const PRODUCT_DEFAULT_IMAGE = '/images/product_default_image.png'

export const DOMAIN_URL = process.env.NODE_ENV === 'production' ? API_PROD_BASE_URL : API_DEV_BASE_URL;