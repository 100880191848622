import React from 'react'
import './index.css';

const GroceryLogo = ({ imagePath, text, imageColor }) => {
    const imageStyle = {
        filter: imageColor ? `drop-shadow(2px 2px 4px ${imageColor})` : 'none',
        backgroundColor: imageColor || 'transparent'
    };

    return (
        <div className="grocery-logo">
            <img src={imagePath} alt="Grocery" className="grocery-image" style={imageStyle} />
            <div className="grocery-text">{text}</div>
        </div>
    );
};


export default GroceryLogo;
