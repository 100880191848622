import React from 'react'
import './index.css';
import ExternalLink from '../ExternalLink';

export default function Education({ education }) {
    return (
        <div className="contactInfo education">
            <h5 className="title">Education</h5>
            <ul>
                {education.map((item) => (
                    <li key={item.id}>
                        <div className="h7">{item.period}</div>
                        <h6>{item.degree_name}</h6>
                        <div className="company_icon">
                            <ExternalLink href={item.school_url} >
                                <img lazyload="" alt={item.image_alt} src={item.school_icon} />
                                <h6>{item.school_name}</h6>
                            </ExternalLink>
                        </div>
                    </li>
                ))}
            </ul>
        </div>

    )
}
