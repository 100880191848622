// grocery Flyer Search

import axios from 'axios';
import {
    FETCH_PRODUCT_DATA_REQUEST,
    FETCH_PRODUCT_DATA_SUCCESS,
    FETCH_PRODUCT_DATA_FAILURE,
    UPDATE_SEARCH_RESULTS,
} from '../constants';


import {
    ALL_PRODUCTS,
    API_URL,
    PRODUCTS_API,
    DOMAIN_URL,
    TEST_PRODUCTS_DATA
} from '../../constants/constants';

// Action creator for fetch data request
export const fetchProductDataRequest = () => ({
    type: FETCH_PRODUCT_DATA_REQUEST,
});

// Action creator for fetch data success
export const fetchProductDataSuccess = (data) => ({
    type: FETCH_PRODUCT_DATA_SUCCESS,
    payload: data,
});

// Action creator for fetch data failure
export const fetchProductDataFailure = (error) => ({
    type: FETCH_PRODUCT_DATA_FAILURE,
    payload: error,
});

// Action creator for updating search results
export const updateSearchResults = (searchTerm) => ({
    type: UPDATE_SEARCH_RESULTS,
    payload: searchTerm,
});

// Async action creator to fetch data from the API
export const fetchData = () => {
    return async (dispatch) => {
        dispatch(fetchProductDataRequest());
        try {
            const response = await axios.get(`${DOMAIN_URL}${API_URL}${PRODUCTS_API}${ALL_PRODUCTS}`);
            // const response = await axios.get(`${TEST_PRODUCTS_DATA}`);
            dispatch(fetchProductDataSuccess(response.data));
        } catch (error) {
            dispatch(fetchProductDataFailure(error));
        }
    };
};
