import React from 'react'
import './index.css'


function Banner({topText, bottomText}) {
    return (
        <div className="general-banner">
            <div className="banner-top-text">{topText}</div>
            <div className="banner-bottom-text">{bottomText}</div>
        </div>
    );
}
function GroceryPageBanner( {topText, bottomText, date} ) {
    return (
        <div className="banner">
            <Banner topText={topText} bottomText={bottomText}/>
            <div className="banner-bottom-date">latest update:</div>
            <div>{date}</div>
        </div>
    );
}

export {GroceryPageBanner, Banner}
