import {
    FETCH_WEBSITE_LOG_REQUEST,
    FETCH_WEBSITE_LOG_SUCCESS,
    FETCH_WEBSITE_LOG_FAILURE,
  } from '../constants'; // Import the action types from constants.js
  

  const initialState = {
    loading: false,
    websiteLog: [],
    error: null,
  };
  
  const websiteLogReducer = (state = initialState, action) => {

    switch (action.type) {
      case FETCH_WEBSITE_LOG_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_WEBSITE_LOG_SUCCESS:
        return {
          ...state,
          loading: false,
          websiteLog: action.payload,
          error: null,
        };
      case FETCH_WEBSITE_LOG_FAILURE:
        return {
          ...state,
          loading: false,
          websiteLog: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default websiteLogReducer;
  