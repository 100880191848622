import React from 'react'
import './index.css'

function RayH5( props ) {
    return (
        <div className='ray_h5'>{props.children}</div>
    )
}

function RayH4( props ) {
    return (
        <div className='ray_h4'>{props.children}</div>
    )
}

export { RayH5, RayH4 };