import React, { useEffect } from 'react';
import LeftComponent from '../LeftComponent';
import RightComponent from '../RightComponent';
import './index.css';

import { connect } from 'react-redux';
import { fetchResumeData } from '../../redux/actions/resume';

function ErrorBoundary({ error, children }) {
  if (error) {
    console.log(error);
    return <p>The server is under maintenance, please try again later</p>;
  }
  return children;
}

function Resume({ jsonData, error, fetchResumeData }) {
  useEffect(() => {
    fetchResumeData();
  }, [fetchResumeData]);

  return (
    <div className='resume-background'>
      <div className="container-max">
        <ErrorBoundary error={error}>
          {jsonData ? (
            <div className="row">
              <div className="col-lg-4 d-flex left-container">
                <LeftComponent data={jsonData} />
              </div>
              <div className="col-lg-8 d-flex right-container">
                <RightComponent data={jsonData} />
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  jsonData: state.resume.jsonData,
  error: state.resume.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchResumeData: () => dispatch(fetchResumeData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resume);